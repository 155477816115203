import './sass/stock.scss';

// https://sabe.io/tutorials/how-to-create-modal-popup-box

var stockApp = (function() {
    // var baseUrl = 'https://stock.tanker2.lndo.site';
    var baseUrl = window.location.origin;

    function init() {
        initModal();
        initMenuButton();
        initFilterButton();
        initOrderButton();
        initCard();
        initArchiveButton();
        initProjectSelectWidget();
        initClearOrdersButton();
        initProjectList();
        initHeaderScroll();
    }

    function initHeaderScroll() {
        // Get the header
        var header = document.getElementById('message');
        if (header) {
            var sticky = header.offsetTop;
            window.onscroll = function() {
                var elmNotEmpty = header.childNodes.length;

                // Get the offset position of the navbar
                if (elmNotEmpty && window.pageYOffset > sticky) {
                    header.classList.add('sticky');
                } else {
                    header.classList.remove('sticky');
                }
            };
        }
    }

    function initMenuButton() {
        var menuButton = document.querySelector('.menu-button');
        if (menuButton) {
            menuButton.addEventListener('click', function(e) {
                document.body.classList.toggle('mmenu-open');
            });
        }
    }

    function initFilterButton() {
        var filterButton = document.querySelector('.filter-button');
        if (filterButton) {
            filterButton.addEventListener('click', function(e) {
                // document.body.classList.toggle('mmenu-open');
                e.preventDefault();
                filterButton.querySelector('.menu-items').classList.toggle('fmenu-open');
            });
        }
    }

    function initProjectSelectWidget() {
        var projectSelect = document.getElementById('project-select-widget');

        // Search current project -> get projectId
        // -> Show project name

        // If no projectId is found 
            // -> show project selector -> select -> Store projectId

        // If no projects exists
            // -> show project create button
                // Redirect back when proejct is added 

        // Store project into localstorage
    }

    function initOrderButton() {
        var addToCardButton = document.getElementById('add-to-card-button');
        var addToCardWidget = document.getElementById('add-to-card-widget');
        var selectAmount = document.getElementById('product-amount');

        if (addToCardButton) {            
            addToCardButton.addEventListener('click', function(event) {
                event.preventDefault();
                
                var productId = addToCardWidget.getAttribute('data-product-id');
                var productStock = addToCardWidget.getAttribute('data-product-stock');
                var productAmount = 0;
                var stockAmountElement = document.getElementById('product-details-stock');

                // Verify projectId
                if (productId) {
                    productAmount = (selectAmount.length) ? selectAmount.options[selectAmount.selectedIndex].value : 0;
                    
                    // Disable button
                    addToCardButton.classList.add('disabled');

                    // Add order
                    addOrder(productId, productAmount, function(result) {
                        if (result) {
                            showMessage('We added your product.');

                            // Update productView
                            productStock = productStock - productAmount;
                            updateSelectAmount(selectAmount, productStock);
                            addToCardWidget.setAttribute('data-product-stock', productStock);

                            if (productStock) {
                                stockAmountElement.innerHTML = productStock;

                                // Enable button
                                addToCardButton.classList.remove('disabled');
                            } else {
                                stockAmountElement.innerHTML = 'Out of stock';
                            }
                        }
                    });
                }
            });
        }
    }

    function initClearOrdersButton() {
        var buttonClearProject = document.querySelector('.button-clear-project');
        
        if (buttonClearProject) {
            var projectId = buttonClearProject.getAttribute('data-entry-id');
            buttonClearProject.addEventListener('click', function(e) {
                e.preventDefault();

                buttonClearProject.classList.add('disabled');

                if (projectId) {
                    if (confirm("Are you sure you wan't to remove all orders of this project?")) {
                        showMessage('Removing products started...');

                        clearProject(projectId, function(result) {
                            if (result) {
                                var productCards = document.querySelectorAll('.card--product');
                                productCards.forEach(function(product) {
                                    // Disabled
                                    disableProductCard(product);
                                });
                                
                                buttonClearProject.classList.remove('disabled');
                                showMessage('All products are removed.');
                            }
                        });
                    } else {
                        buttonClearProject.classList.remove('disabled');
                    }
                }
            });
        }
    }

    function disableProductCard(product) {
        product.classList.add('disabled');
        
        var removeButton = product.querySelector('.button-remove');
        if (removeButton) {
            removeButton.classList.add('disabled');
        }

        var selectAmount = product.querySelector('select.product-amount');
        if (selectAmount) {
            selectAmount.disabled = true;
        }
    }

    function clearProject(projectId, callback) {
        var data = new FormData();
        data.append(csrfTokenName, csrfTokenValue);
        data.append('action', 'stock-module/default/clear-project');
        data.append('projectId', projectId);

        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", baseUrl, true);
        xhttp.setRequestHeader('Accept', 'application/json'); 
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == XMLHttpRequest.DONE) {
                var response = JSON.parse(xhttp.responseText);
                callback(response.success);
            }
        }

        xhttp.send(data);
    }

    function initArchiveButton() {
        var projectList = document.getElementById('project-list');
        if (projectList) {
            var projects = document.querySelectorAll('.button-archive');
            projects.forEach(function(project) {
                if (project) {
                    _initArchiveButton(project);
                }
            });
        }

        var projectDetail = document.getElementById('project-detail');
        if (projectDetail) {
            var project = document.querySelector('.button-archive');
            if (project) {
                _initArchiveButton(project);
            }
        }
    }

    function _initArchiveButton(elm, callback = null) {
        var archiveButton = elm;
        var entryId = elm.getAttribute('data-entry-id');
        
        // Init remove button
        if (archiveButton) {
            archiveButton.addEventListener('click', function(e) {
                e.preventDefault();

                // Disabled
                archiveButton.classList.add('disabled');

                if (confirm("Are you sure you wan't to archive this project?")) {
                    // Archive project
                    showMessage('Archiving project is started...');

                    archiveProject(entryId, function(result) {
                        if (result) {
                            showMessage('Your project is archived.');

                            if (typeof callback === 'function') {
                                callback();
                            }
                        } else {
                            console.log('Archive failed');
                        }
                    });
                } else {
                    // Remove disabled
                    archiveButton.classList.remove('disabled');
                }
            });
        }
    }

    function updateSelectAmount($elm, $amount) {
        var options = '';
        for (let step = 0, value = 1; step < $amount; step++, value++) {
            options += '<option value="' + value + '">' + value + '</option>'
        }
 
        if (options) {
            $elm.innerHTML = options;
        } else {
            $elm.innerHTML = '<option value="0">0</option>'
        }
    }

    // Controller create-order check available amount
    function addOrder(productId, productAmount, callback) {
        var data = new FormData();
        data.append(csrfTokenName, csrfTokenValue);
        data.append('action', 'stock-module/default/create-order');
        data.append('productId', productId);
        // data.append('projectId', projectId);
        data.append('productAmount', productAmount);

        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", baseUrl, true);
        xhttp.setRequestHeader('Accept', 'application/json'); 
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == XMLHttpRequest.DONE) {
                var response = JSON.parse(xhttp.responseText);
                callback(response.success);
            }
        }

        xhttp.send(data);
    }

    function showMessage($message) {
        var messageElement = document.getElementById('message');
        messageElement.innerHTML = '<div class="inner">' + $message + '</div>';
    }

    function updateOrder(entryId, productAmount) {
        var data = new FormData();
        data.append(csrfTokenName, csrfTokenValue);
        data.append('action', 'stock-module/default/update-order');
        data.append('entryId', entryId);
        data.append('productAmount', productAmount);

        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", baseUrl, true);
        xhttp.send(data);
    }

    function initCard() {
        var card = document.getElementById('card');
        if (card) {
            var products = document.querySelectorAll('.card--product');
            products.forEach(function(product) {
                var entryId = product.getAttribute('data-entry-id');

                // Init select amount
                var selectAmount = product.querySelector('select.product-amount');
                if (selectAmount) {
                    selectAmount.addEventListener('change', function(e) {
                        updateOrder(entryId, selectAmount.value);
                    });
                }

                // Init remove button
                var removeButton = product.querySelector('.button-remove');
                if (removeButton) {
                    removeButton.addEventListener('click', function(e) {
                        e.preventDefault();

                        // Disabled
                        product.classList.add('disabled');
                        selectAmount.disabled = true;
                        deleteOrder(entryId, function(result) {
                            if (result) {
                                // Delete success
                            }
                        });
                    });
                }
            });
        }
    }

    function deleteOrder(entryId, callback) {
        var data = new FormData();
        data.append(csrfTokenName, csrfTokenValue);
        data.append('action', 'stock-module/default/delete-order');
        data.append('entryId', entryId);

        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", baseUrl, true);
        xhttp.setRequestHeader('Accept', 'application/json'); 
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == XMLHttpRequest.DONE) {
                var response = JSON.parse(xhttp.responseText);
                callback(response.success);
            }
        }

        xhttp.send(data);
    }

    function archiveProject(projectId, callback) {
        var data = new FormData();
        data.append(csrfTokenName, csrfTokenValue);
        data.append('action', 'stock-module/default/archive-project');
        data.append('projectId', projectId);

        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", baseUrl, true);
        xhttp.setRequestHeader('Accept', 'application/json'); 
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == XMLHttpRequest.DONE) {
                var response = JSON.parse(xhttp.responseText);
                callback(response.success);
            }
        }

        xhttp.send(data);
    }

    function setActiveProject(projectId, callback) {
        var data = new FormData();
        data.append(csrfTokenName, csrfTokenValue);
        data.append('action', 'stock-module/default/set-active-project');
        data.append('projectId', projectId);

        var xhttp = new XMLHttpRequest();
        xhttp.open("POST", baseUrl, true);
        xhttp.setRequestHeader('Accept', 'application/json'); 
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == XMLHttpRequest.DONE) {
                var response = JSON.parse(xhttp.responseText);
                callback(response);
            }
        }

        xhttp.send(data);
    }

    function initModal() {
        var modal = document.querySelector(".modal");
        // var trigger = document.querySelector(".trigger");
        var closeButton = document.querySelector(".close-button");
        var switchProjectButton = document.getElementById('switch-project-button');

        function windowOnClick(event) {
            if (event.target === modal) {
                toggleModal();
            }
        }

        if (switchProjectButton) {
            switchProjectButton.addEventListener('click', function(event) {
                event.preventDefault();
                toggleModal();
            });
        }

        if (closeButton) {
            closeButton.addEventListener("click", toggleModal);
        }

        window.addEventListener("click", windowOnClick);
    }

    function toggleModal() {
        var modal = document.querySelector(".modal");
        modal.classList.toggle("show-modal");
    }

    function initProjectList() {
        var selectItems = document.querySelectorAll('.project-item');
        selectItems.forEach(function(item) {
            item.addEventListener('click', function(evt) {
                evt.preventDefault();
                var projectId = item.getAttribute('data-project-id');

                setActiveProject(projectId, function(result) {
                    if (result) {
                        if (result['success'] && result['title']) {
                            document.getElementById('active-project-title').innerHTML = result['title'];
                            document.getElementById('add-to-card-button').classList.remove('hide');
                            document.getElementById('switch-project-button').innerHTML = 'Select another project';
                        }
                    } else {
                        console.log('Set active project failed');
                    }
                });

                toggleModal();
            });
        });
    }

    init();

})(csrfTokenName, csrfTokenValue);